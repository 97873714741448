.html-part {
    overflow-y: auto;

    sup {
        top: -0.2em;
    }

    section:last-of-type {
        margin-bottom: 120px;
    }

    .footnotes-visible & {
        section:last-of-type {
            margin-bottom: 75vh;
        }
    }
}

.bottom-copyright,
.top-copyright,
.prevnext {
    display: none;
}

section.calibre {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

sup {
    top: -0.2em;
}

p a {
    color: #0000ee;
}

.loading-wrapper {
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
}
