nav.menu {
    position: fixed;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    width: 400px;
    max-width: 85vw;
    border-right: 1px solid #333;
}

.toolbar {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    border-bottom: 1px solid #333;
}

.bible-container img {
    max-width: 100%;
    height: auto;
}

.bible-container {
    margin-top: 40px;
}

[id]::before {
    display: block;
    content: " ";
    margin-top: -0.5em;
    height: 0.5em;
    visibility: hidden;
    pointer-events: none;
}
