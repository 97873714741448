.bible-search-drawer > div {
  max-width: 85vw;
  width: 800px;
  /*-ms-overflow-style: none;  !* IE and Edge *!*/
  /*scrollbar-width: none;  !* Firefox *!*/
}

.bible-search-drawer > div::-webkit-scrollbar {
  /*display: none;*/
}
