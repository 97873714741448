.search-panel {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

[class^='ais-'] {
  font-size: 1rem;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.ais-SearchBox {
  width: 100%;
  display: flex;
  margin: 10px 0;
}

.ais-SearchBox-form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.ais-SearchBox-input {
  width: 100%;
  border: 2px solid #3f51b5;
  border-right: none;
  padding: 5px;
  height: 22px;
  border-radius: 2px 0 0 2px;
  outline: none;
  color: #8886a3;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

.ais-SearchBox-input:focus{
  color:#333;
}


.ais-SearchBox-submit {
  width: 40px;
  height: 36px;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  text-align: center;
  color: #fff !important;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
  right: 0;
  position: relative;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  padding: 0;
  user-select: none;
  transform: translateY(-50%);
  cursor: pointer;
}


.ais-SearchBox-submitIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ais-SearchBox-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 5px;
}

.ais-SearchBox-resetIcon {
  position: absolute;
  left: -27px;
  width: 12px;
  height: 12px;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  fill: #495588;
}


.ais-SearchBox-submit svg path {
  fill: white;
}

.hits {
  width: 100%;
  position: relative;
  display: flex;
  margin: 0 auto;
}

.ais-Hits {
  width: 100%;
  display: flex;
  position: relative;
  margin: 0 auto;
}

.ais-Hits--empty {
  box-sizing: border-box;
  background-color: green;
  position: absolute;
  padding: 1rem;
  margin: 0;
  margin-top: 5px;
  color:#8886a3;
}

.ais-Hits .ais-Hits-list {
  display: block;
  padding: 0;
  margin: 0;
}

.ais-Hits .ais-Hits-list .ais-Hits-item {
  display: block;
}

.ais-Hits-list .ais-Hits-item:first-of-type .note-head {
  margin-top: 0;
}

.ais-Hits-list .ais-Hits-item:last-child {
  margin-bottom: 30px;
}

.ais-Highlight-highlighted {
  background-color: #ffbf00ba;
  font-style: normal;
}

:-moz-placeholder {
  color: #a7aabc;
  font-weight: 200;
}

::-webkit-input-placeholder {
  color: #a7aabc;
  font-weight: 200;
}

.search-index-toggle * {
  font-size: 0.9rem !important;
}

.pagination {
  margin-bottom: 30px;
}

.pagination-stats {
  margin: 10px 0;
}
.pagination-stats span {
  font-size: 0.80em;
  font-style: italic;
  display: block;
}

.ais-Pagination-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ais-Pagination-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

a[class^=ais-] {
  text-decoration: none;
}

.ais-Pagination {
  color: #3a4570;
}

.ais-Pagination-link {
  color: #3f51b5;
  transition: color .2s ease-out;
}

.ais-Pagination-link:focus, .ais-Pagination-link:hover {
  color: #0073a8;
}

.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: .3rem;
}

.ais-Pagination-link {
  padding: .3rem .6rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  transition: background-color .2s ease-out;
}

.ais-Pagination-link:focus, .ais-Pagination-link:hover {
  background-color: #e3e5ec;
}

.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: .6;
  cursor: not-allowed;
  color: #a5abc4;
}

.ais-Pagination-item--disabled .ais-Pagination-link:focus, .ais-Pagination-item--disabled .ais-Pagination-link:hover {
  color: #a5abc4;
  background-color: #fff;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.ais-Pagination-item--selected .ais-Pagination-link:focus, .ais-Pagination-item--selected .ais-Pagination-link:hover {
  color: #fff;
}
