.chapter-nav a {
  display: inline-block;
  padding: 2px 3px;
  margin: 2px;
}

.chapter-nav {
  width: 800px;
  max-width: 80vw;
}
