.footnotes-wrapper {
    > div {
        overflow-y: visible;
    }

    .html-part {
        max-height: 70vh;
    }
}

.footnote-panel-close {
    position: absolute;
    right: 10px;
    top: -50px;
    background: white;
    border-radius: 50%;
}
